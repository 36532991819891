// src/views/Home.vue

<template>



  <div class="pa-8 " >


    <!-- <v-img v-bind="props" class="hintergrund_gradient_animation" style="height:1000px !important" >fff</v-img> -->

      <h2 class="primary--text mb-7">Maritime-Tech-Manager-Online</h2>
         
      <br>Der Maritime-Tech-Manager ist ein <img src='../../public/img/schiff_small.jpg' class="css_srk" style="border:5px 7px 6px -2px rgba(0,0,0,0.26)"/>modularer Aufbau eines webbasierten Datenbank-Systems. 

    
       <br><br>
      Damit sind Anwendungen wie Planungen von Werftzeiten, Reparaturen, Checklisten, Erfassung und Erkennen kritischer Parameter,  
      Bereitstellung von Betriebsanleitungen und Wartungsplänen und vieles mehr sehr einfach zu realisieren. 
      
      <br><br>
      Auf Grund des modularen Aufbaus und der technischen Infrastruktur ist eine vielseitige Erweiterung des Systems problemlos möglich (z.B. Push Nachrichten in Echtzeit, mobile App-Anwendungen, und vieles mehr). 

          




  </div>

</template>

<script>

import SocketioService from '@/services/socketio.service.js';


import AuthService from '@/services/AuthService.js';

import { EventBus } from '../main';


export default {
  data() {
    return {
      secretMessage: '',
      username: '',
      angemeldeteruser:  localStorage.getItem("angemeldeter_user"),
      idtest:''
    };
  },
  async created() {

 this.idtest = this.$route.params.id; 

    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login');
    }

    this.username = this.$store.getters.getUser.username;
    this.secretMessage = await AuthService.getSecretContent();


    //**********  Socket , siehe socket,io  service ****/
    SocketioService.setupSocketConnection();
  },
  
  methods: {
   
    testEventBus() {
      EventBus.$emit('testeventbus','TEEEST')
    },

    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    }
  },
  props: {
        id: String
      },
     
};
</script>

<style scoped>


.css_srk {
 border:1px solid black;
 -webkit-box-shadow: 5px 7px 6px -2px rgba(0,0,0,0.26); 
box-shadow: 5px 7px 6px -2px rgba(0,0,0,0.26);
}

@media (max-width: 600px) {
        .css_srk {
          width:170px !important;
          height:auto;
         float:right; margin:5px; opacity:0.5;
        }
        .css_srb {
          width:100px !important;
          height:auto;
         float:left; margin:5px; opacity:0.5;
        }
 }

 @media (min-width: 600px) {

        .css_srk {
         float:right; margin:15px; opacity:0.5;
        }
        .css_srb {
          width:150px !important;
          height:auto;
         float:left; margin:10px; opacity:0.5;
        }
 }

 @media (min-width: 1400px) {

.css_srk {
 float:right; margin:22px; opacity:0.8;
 border:1px solid black;
 -webkit-box-shadow: 5px 7px 6px -2px rgba(0,0,0,0.26); 
box-shadow: 5px 7px 6px -2px rgba(0,0,0,0.26);
}
.css_srb {
 
 float:left; margin:22px; opacity:0.8;
}
}


      /* https://www.sliderrevolution.com/resources/css-animated-background/ */
  

.hinterani {
  height: 100%;
  /* max-height: 600px; */
  width: 100%;
  /* background-color: hsla(200,40%,30%,.4); */
  background-color: rgba(255, 255, 255, 0.4);
  background-image:  
  url('../../public/img/46_Meter.gif'), 
  url('../../public/img/28_Meter.gif'),
  url('../../public/img/10_Meter.png');
    /* url('https://78.media.tumblr.com/cae86e76225a25b17332dfc9cf8b1121/tumblr_p7n8kqHMuD1uy4lhuo1_540.png'), 
    url('https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png'),
    url('https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png'),
    url('https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png'),
    url('https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png'); */
  background-repeat: repeat-x;
  background-position: 
    /* 0 20%,
    0 100%,
    0 50%,
    0 100%,
    0 0; */
    0% 100%,
    40% 90%,
    70% 85%;
  background-size: 
    /* 2500px,
    800px,
    500px 200px,
    1000px,
    400px 260px; */
    200px,
    100px,
    30px;
   
    /* The first value is the horizontal position and the second value is the vertical. 
    The top left corner is 0% 0%. The right bottom corner is 100% 100%. 
    If you only specify one value, the other value will be 50%. 
    Default value is: 0% 0% */

  animation: 90s para infinite linear;
  }

@keyframes para {
  100% {
    background-position: 
      100% 100%,
      100% 90%,
      100% 85%;
     
    }
  }

    



</style>