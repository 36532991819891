<template>


    <div mdi-view-dashboard>

        <h2 class="primary--text  ma-6 pa-0">Gruppen-Modul-Liste</h2>

     

    <!-- Auswahl     https://codingbeautydev.com/blog/vuetify-select/                append-outer-icon="mdi-ferry"     -->
    <!-- https://vue-select.org/guide/values.html#getting-and-setting -->
    <!-- https://stackoverflow.com/questions/49498674/vuetify-v-select-options-from-json-array-of-objects -->

        <v-row justify="left" class="ma-0 pa-0" >
            <v-col sm="4">
                <v-select  class="mb-0 ml-0 pa-0 black--text "  @change="zeige_gruppenmodule()" outlined   
                :items="schiffsliste_auswahl"
                return object 
                v-model=selected_id_sar 
                item-text="schiff_name"
                item-value= "id_sar"
                label="wähle Schiff"> 
                </v-select>
            </v-col>
       
            <v-col sm="4 ">
                <v-card-title class="mt-n6" >
                    <v-text-field  n5
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Filter"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
            </v-col>
        </v-row>



    <!-- https://vuetifyjs.com/en/components/data-tables/#dense -->
        <v-data-table
            dense
            :headers="headers"
            :items="gruppenmodulliste"
            :search="search"
            item-key="id_zuordnung_final_gmv_sar"
            sort-by="GM"
            multi-sort
            group-by="Bereich"
            :class="elevation-1"
            show-group-by
            group-asc
            :items-per-page="25"
            :mobile-breakpoint="2"

            :loading="ladetabelle"
            loading-text="Lade Daten... "

            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            
        >

        
            <!-- ************  macht aus der bereichsüberschrift Großbuchstaben *********** -->
            <template v-slot:header.Bereich="{ header }">
                <span style="font-weight:bold; color:black">{{ header.text.toUpperCase() }} </span>
            </template>
        



            <!--************** Ausklapp Zeile mit weiteren Infos **************-->
             <template v-if: item.bezeichnung   v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                       interne Bezeichnung: {{ item.bezeichnung }}  - Seriennummer: {{ item.seriennummer }}    
                    </td>
             </template>


            <!-- ************  Ruft Popup Aufgabe auf und übergibt Parameter (id, etc) -- im Popup selbst (in der Komponente) wird der angezeigte "Link" erzeugt (z.B. Symbol Bleistift)*********** -->
                <!-- das   @emit_closepopup   wird vom popup ausgelöst bei Neueintrag neuer Datensatz, um Fenster zu Liste neu zu laden (mit aktueller Anzahl)) -->
            <template v-slot:item.id_zuordnung_final_gmv_sar="{ item }" >
                <div class=" flex-nowrap d-flex align-center">
                    <Popup_aufgabe   @emit_closepopup ="popupgeschlossen"  title="Aufgaben / ToDo anzeigen" herkunft="von_gruppenmodulliste"  :current_user_id=current_user_id  :id_sar =item.id_sar  :prop_id_aufgabe =item.id_aufgabe :id_typ_sar =item.id_SAR_Typ  :schiff_name =item.schiff_name  :anzahl_aufgaben=item.anzahl_aufgaben  :id_gmv =item.id_gmv :id_emv =0  :gruppen_modul_bezeichnung =item.GM  :einzel_modul_bezeichnung =0  :id_zuordnung_final_gmv_sar = item.id_zuordnung_final_gmv_sar      :id_zuordnung_final_emv_sar =0  class="ma-3"></Popup_aufgabe> 
                    <Popup_infos @emit_closepopup ="popupgeschlossen"  title="Infos anzeigen" :id_sar =item.id_sar :current_user_id=current_user_id  :id_typ_sar =item.id_SAR_Typ  :schiff_name =item.schiff_name  :anzahl_infos=item.anzahl_infos  :id_gmv =item.id_gmv :id_emv =0  :gruppen_modul_bezeichnung =item.GM  :einzel_modul_bezeichnung =0  :id_zuordnung_final_gmv_sar = item.id_zuordnung_final_gmv_sar   :id_zuordnung_final_emv_sar =0   class="ma-3"></Popup_infos> 
                </div>

            </template>

         

            <!-- <template v-slot:item.EM="{ item }">
                {{ item.EM  }}{{ item.anzahl_aufgaben  }}
            </template> -->

        </v-data-table>

        
        
    </div> 

</template>




<script>

import GruppenmodulService from '@/services/GruppenmodulService.js';
import SchiffeService from '../services/SchiffeService';
import Popup_aufgabe from '../components/Popup_aufgabe.vue';
import Popup_infos from '../components/Popup_infos.vue';


import AuthService from '@/services/AuthService.js';

// import { EventBus } from '../main';


export default {
    data() {
            return {

                //einzelmodulliste: "",
                schiffsliste:"",
                schiffsliste_auswahl:"",
                selected_id_sar: localStorage.id_sar_ausgewaehlt_global,
                selected_name_sar : localStorage.name_sar_ausgewaehlt_global,
                in_liste_schiff_alt:'',
                current_user_id:'',
                
                search: '',
                
                headers: [
                {
                    text: 'Bereich',
                    align: 'start d-none d-sm-table-cell',  //********  d-none d-lg-table-cell   = wird nur bei größeren Viewports (lg = large) angezeigt :   https://stackoverflow.com/questions/59871140/vuejs-how-to-hide-some-data-table-columns-on-mobile-size */
                    value: 'Bereich',
                    groupable: true,
                },
                { text: 'GM', value: 'GM', align: 'start', groupable: false },
              
                { text: 'Bezeichnung', value: 'bezeichnung', align: 'left  start d-none d-md-table-cell', groupable: false },  //********  d-none d-md-table-cell   = wird nur bei größeren Viewports (md=Medium)angezeigt :
                { text: 'Seriennummer', value: 'seriennummer', align: 'left  start d-none d-md-table-cell' , groupable: false},  //********  d-none d-md-table-cell   = wird nur bei größeren Viewports (md=Medium)angezeigt :
                { text: '', value: 'data-table-expand' , align: 'start hidden-xs-and-down   hidden-md-and-up ', sortable: false, groupable: false},  //********  verstecken ab mittleren Viewports und höher :  hidden-md-and-up
               
                { text: 'Aktion', value: 'id_zuordnung_final_gmv_sar', sortable: true, groupable: false  },
                ],
                expanded: [],
                singleExpand: true, //**********  für Tabellenzeie Info ausklappen (immer nur eine oder beliebig viele)) */
                gruppenmodulliste: [],
               
            };
    },
    methods: {   



        async hole_user_id() {
                try {
                    const credentials = {
                    username: this.username,
                    password: this.password
                    };
                    const response = await AuthService.hole_user_id(credentials);
                    
                    
                    this.current_user_id = response;
                   
                }
                catch(err){
                    console.log('token is null: ',err);
                }
        },






        popupgeschlossen(){
           // alert(wert);
            this.zeige_gruppenmodule();
        },


        async zeige_sar_einheit_auswahl() {
            
                try {

                    //var id_user =  localStorage.getItem("user_id");  //  user_id aus dem localstorage
                    //var id_user = this.$store.state.user_id;   //  user_id aus dem VUEX-Store


                    const credentials = {
                       // id_user: id_user
                    };

                    const response = await SchiffeService.zeigeschiffe(credentials);
                    if (typeof response.msg !=='undefined'){this.$router.push("/login")} //****  wenn keine Anmeldung */
                    this.schiffsliste_auswahl = response.recordset;
                }
                catch (error) {
                // this.msg = error.response.data.aufgabenliste;
                }
            },


            async zeige_gruppenmodule() {
               



                //*** wenn Sar-Einheit in Dropdown ausgewählt wurde, dann diesen Wert in localStorage speichern //******  globale session variabel setzen  (siehe in App.vue  / mounted und watch ) */
                if(typeof(this.selected_id_sar) !== "undefined" && this.selected_id_sar !=='' ) { localStorage.id_sar_ausgewaehlt_global = this.selected_id_sar  } 

                this.ladetabelle = true

                try {
                    var credentials = {
                        id_sar:  localStorage.id_sar_ausgewaehlt_global
                    };

                   
                    // Hier werden die Daten abgerufen (aufruf axios in script)
                    const response = await GruppenmodulService.zeigegruppenmodule(credentials);

                    if (typeof response.msg !=='undefined'){this.$router.push("/login")} //****  wenn keine Anmeldung */ 

                    this.ladetabelle = false  //*****  Lade Daten Symbol (Stfreifen)) */
                    this.gruppenmodulliste = response.recordset;
                    
                    //************* setze den ausgewählten Schiffsnamen *******/
                    this.selected_name_sar = this.gruppenmodulliste[1].schiff_name
                    this.selected_id_sar = this.gruppenmodulliste[1].id_sar

                }
                catch (error) {
                // this.msg = error.response.data.aufgabenliste;
                }
            },


            zum_login() {
                this.$router.push("/login");
            }

    },

    created() {

        
            if (!this.$store.getters.isLoggedIn) {
                this.$router.push('/login');
            }

          
            //  wenn name_sar_ausgewaehlt_global   in localStorage   bei  Modulaufruf  nicht leer ist, dann setze Anzeige ausgewähltes Schiff (selected_name_sar) aus localStorage)
            if(typeof(localStorage.name_sar_ausgewaehlt_global) !== "undefined" && localStorage.name_sar_ausgewaehlt_global !=='' ) {
                this.selected_name_sar = localStorage.name_sar_ausgewaehlt_global
            }

            this.hole_user_id();

            this.zeige_sar_einheit_auswahl();
            this.zeige_gruppenmodule() ;
            // EventBus.$on('testeventbus', eventbusparameter => {
            // this.eventbusmsg =eventbusparameter
            // })
    },

    components: { 
      Popup_aufgabe,
      Popup_infos
    }
};

</script>


<style scoped>



</style>
