var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"mdi-view-dashboard":""}},[_c('h2',{staticClass:"primary--text  ma-6 pa-0"},[_vm._v("Gruppen-Modul-Liste")]),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"left"}},[_c('v-col',{attrs:{"sm":"4"}},[_c('v-select',{staticClass:"mb-0 ml-0 pa-0 black--text ",attrs:{"outlined":"","items":_vm.schiffsliste_auswahl,"return":"","object":"","item-text":"schiff_name","item-value":"id_sar","label":"wähle Schiff"},on:{"change":function($event){return _vm.zeige_gruppenmodule()}},model:{value:(_vm.selected_id_sar),callback:function ($$v) {_vm.selected_id_sar=$$v},expression:"selected_id_sar"}})],1),_c('v-col',{attrs:{"sm":"4 "}},[_c('v-card-title',{staticClass:"mt-n6"},[_c('v-text-field',{attrs:{"n5":"","append-icon":"mdi-magnify","label":"Filter","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{class:_vm.elevation-1,attrs:{"dense":"","headers":_vm.headers,"items":_vm.gruppenmodulliste,"search":_vm.search,"item-key":"id_zuordnung_final_gmv_sar","sort-by":"GM","multi-sort":"","group-by":"Bereich","show-group-by":"","group-asc":"","items-per-page":25,"mobile-breakpoint":2,"loading":_vm.ladetabelle,"loading-text":"Lade Daten... ","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header.Bereich",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-weight":"bold","color":"black"}},[_vm._v(_vm._s(header.text.toUpperCase())+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" interne Bezeichnung: "+_vm._s(item.bezeichnung)+" - Seriennummer: "+_vm._s(item.seriennummer)+" ")])]}},{key:"item.id_zuordnung_final_gmv_sar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:" flex-nowrap d-flex align-center"},[_c('Popup_aufgabe',{staticClass:"ma-3",attrs:{"title":"Aufgaben / ToDo anzeigen","herkunft":"von_gruppenmodulliste","current_user_id":_vm.current_user_id,"id_sar":item.id_sar,"prop_id_aufgabe":item.id_aufgabe,"id_typ_sar":item.id_SAR_Typ,"schiff_name":item.schiff_name,"anzahl_aufgaben":item.anzahl_aufgaben,"id_gmv":item.id_gmv,"id_emv":0,"gruppen_modul_bezeichnung":item.GM,"einzel_modul_bezeichnung":0,"id_zuordnung_final_gmv_sar":item.id_zuordnung_final_gmv_sar,"id_zuordnung_final_emv_sar":0},on:{"emit_closepopup":_vm.popupgeschlossen}}),_c('Popup_infos',{staticClass:"ma-3",attrs:{"title":"Infos anzeigen","id_sar":item.id_sar,"current_user_id":_vm.current_user_id,"id_typ_sar":item.id_SAR_Typ,"schiff_name":item.schiff_name,"anzahl_infos":item.anzahl_infos,"id_gmv":item.id_gmv,"id_emv":0,"gruppen_modul_bezeichnung":item.GM,"einzel_modul_bezeichnung":0,"id_zuordnung_final_gmv_sar":item.id_zuordnung_final_gmv_sar,"id_zuordnung_final_emv_sar":0},on:{"emit_closepopup":_vm.popupgeschlossen}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }