



//*************  Beispiel für Socket.io:    https://deepinder.me/creating-a-real-time-chat-app-with-vue-socket-io-and-nodejs */


//*****************************  socket param auch anpassen !!!!!!!!!!  (Datei: socket_param.env)    ) */



module.exports.config_server_adresse = {

    
// url : 'http://localhost:1385/api/',   //  ***** API-Anwendung
// url_foto_anzeigen:'http://localhost:1380/uploader'  //  ***** Hauptanwendung




    url : 'https://api-maritime.tech-manager.online/api/',
   url_foto_anzeigen:'https://maritime.tech-manager.online/uploader'  //  ***** Hauptanwendung Fotos anzeigen


};