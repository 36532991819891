<template>


    <div mdi-view-dashboard>

        <h2 class="primary--text  ma-6 pa-0">Stationsverbräuche</h2>

     

    <!-- Auswahl     https://codingbeautydev.com/blog/vuetify-select/                append-outer-icon="mdi-ferry"     -->
    <!-- https://vue-select.org/guide/values.html#getting-and-setting -->
    <!-- https://stackoverflow.com/questions/49498674/vuetify-v-select-options-from-json-array-of-objects -->

        <v-row justify="left" class="ma-0 pa-0" >
            <v-col sm="4">
                <v-select  class="mb-0 ml-0 pa-0 black--text "  @change="zeige_stationsverbraeuche()" outlined   
                :items="stationslistesliste_auswahl"
                return object 
                v-model="selected_id_station"
                item-text="stationsname"
                item-value= "id_station"
                label="wähle Station"> 
                </v-select>
            </v-col>
       
            <v-col sm="4 ">
                <v-card-title class="mt-n6" >
                                            <v-menu
                                                v-model="zeige_kalender"
                                                :close-on-content-click="false"
                                                :nudge-right="140"
                                                lazy
                                                transition="scale-transition"
                                                offset-y
                                                full-width
                                                max-width="290px"
                                                min-width="290px"
                                              >
                                                <template v-slot:activator="{ on }">
                                                  <v-text-field class="calenderfeld" style="color:red; font-size:15px;"
                                                  v-model="neuesdatum_fuer_anzeige"
                                                    label="Datum"
                                                    persistent-hint
                                                    prepend-icon="mdi-calendar"
                                                    v-on="on"
                                                  ></v-text-field>
                                                </template>
                                               
                                                <v-date-picker header-color="primary" locale="de" v-model="datum"  @input="zeige_kalender = false"
                                                @change= "erhalte_datum([index, $event])" ></v-date-picker>
                                            </v-menu>

                    
                </v-card-title>

                        <!-- **********  Einfügen Button ************ -->
                        <div style="position:relative; float:right !important;  z-index:999999999999; width:auto; margin: -80px 10px 0px 0px;"> 
                                    <v-icon  color="green darken-2"  @click="stationsverbrauch_einfuegen()">mdi-pencil-plus</v-icon> 
                        </div> 

          

            </v-col>
        </v-row>



    <!-- https://vuetifyjs.com/en/components/data-tables/#dense -->
        <v-data-table
            dense
            :headers="headers"
            :items="stationsverbrauchsliste"
            item-key="id_station_verbrauch"
            multi-sort
            :class="elevation-1"
            :items-per-page="25"
            :mobile-breakpoint="2"
            :loading="ladetabelle"
            loading-text="Lade Daten... "
        >

       
        <template v-slot:item.bemerkungen_vorlage="{ item }" >
            <div style=" padding: 7px 0px 5px 0px; width:100px; font-weight:bold;">{{ item.bemerkungen_vorlage  }}  </div>
            <div style=" padding: 1px 0px 7px 0px; width:100px; color:#669999; " class=" schriftgroesse_normal"> {{ item.verbraucher_beschreibung  }} </div>
            <div style=" padding: 1px 0px 7px 0px; width:100px; color:#669999; " class=" schriftgroesse_normal"> {{ item.zaehler_nummer}} </div>
        </template> 
        

        <template v-slot:item.ablesedatum="{ item }" >
            <span style="font-size:0px; color:transparent; ">{{ item.ablesedatum.substr(8, 2)  + '.' + item.ablesedatum.substr(5, 2)+ '.' +item.ablesedatum.substr(0, 4)   }}</span> 
            <div style="display: table-cell; max-width:20%; overflow:hidden;" class=" schriftgroesse_kurztext"> {{ item.ablesedatum.substr(8, 2)  + '.' + item.ablesedatum.substr(5, 2)+ '.' +item.ablesedatum.substr(0, 4) }}
            </div>
        </template> 


        <template v-slot:item.zaehlerstand1="{ item }" >
                    <span style="font-size:10px; width:30px; margin-top:30px; float:left; ">{{ item.verbrauch_einheit  }}</span> 
                    <div style="white-space:nowrap; float:left; width: 100px;">
                        <v-text-field
                          v-model="item.zaehlerstand1"
                          @change= "erhalte_wert_von_textfeld([index, $event,item.id_station_verbrauch,'id_station_verbrauch','zaehlerstand1','tbl_station_verbrauch'])"
                          hide-details="auto"
                          class="pa-2    css-text-rot "
                          style="width:100px !important; font-size:14px;"
                        ></v-text-field>
                    </div>
        </template>                    

        

        </v-data-table>
        
    </div> 

</template>


<script>

// import SchiffeService from '../services/SchiffeService';
// import Popup_infos from '../components/Popup_infos.vue';

import StationsverbraeucheService from '@/services/StationsverbraeucheService.js';
import AuthService from '@/services/AuthService.js';

import AufgabenService from '@/services/AufgabenService.js';

// import { EventBus } from '../main';

export default {
    data() {
            return {
                datum:  (new Date(Date.now() )).toISOString().substr(0, 10),
                date: (new Date(Date.now() )).toISOString().substr(0, 10),            
                menu: false,
            aufgabe_bis_datum:"",
            zeige_kalender:false,
            dateFormatted: '',
            neuesdatum: null,
            neuesdatum_fuer_anzeige: null,

                id_station: null,

                stationslistesliste_auswahl:"",
                selected_id_station: localStorage.id_station_ausgewaehlt_global,
               // selected_name_sar : localStorage.name_sar_ausgewaehlt_global,
                
                current_user_id:'',
               
                search_checks: '',
                headers: [
                // {
                //     text: 'Bereich',
                //     align: 'start d-none d-sm-table-cell',  //********  d-none d-lg-table-cell   = wird nur bei größeren Viewports (lg = large) angezeigt :   https://stackoverflow.com/questions/59871140/vuejs-how-to-hide-some-data-table-columns-on-mobile-size */
                //     value: 'Bereich',
                //     groupable: true,
                // },
//                 { text: 'Modul', value: 'gruppen_modul_bezeichnung', align: 'start', groupable: false },
//               
// 
                { text: 'Verbraucher', value: 'bemerkungen_vorlage', align: 'left  ', groupable: false},  //********  d-none heißt: ausblenden !! Ist aber wichtig für FILTERUNG       d-md-table-cell   = wird nur bei größeren Viewports (md=Medium)angezeigt :
                { text: 'Ablesedatum', value: 'ablesedatum', align: 'left   ' , groupable: false},  //********  d-none d-md-table-cell   = wird nur bei größeren Viewports (md=Medium)angezeigt :
                { text: 'Zähler-Stand', value: 'zaehlerstand1', align: 'left  ' , groupable: false,  visible: false},  //********  d-none d-md-table-cell   = wird nur bei größeren Viewports (md=Medium)angezeigt :

            ],
                expanded: [],
                singleExpand: true, //**********  für Tabellenzeie Info ausklappen (immer nur eine oder beliebig viele)) */
                stationsverbrauchsliste: [],
               
            };
    },
    methods: {   

       
        
        erhalte_datum() {

            this.neuesdatum = this.datum.substr(5, 2)+ '.' +this.datum.substr(8, 2) + '.'+this.datum.substr(0, 4)
            this.neuesdatum_fuer_anzeige = this.datum.substr(8, 2) + '.' +this.datum.substr(5, 2)+ '.'+this.datum.substr(0, 4)
             //alert(this.neuesdatum_fuer_anzeige);
            
         },
 

         async stationsverbrauch_einfuegen () {

                if(this.neuesdatum !== null){

               try {
                    var credentials_neu = {
                        id_station: localStorage.id_station_ausgewaehlt_global,
                        datum: this.neuesdatum
                    };

                  
                    const response = await StationsverbraeucheService.neuen_stationsverbrauch_einfuegen(credentials_neu);
                        if (response.msg) { //**********  keine gültige Session */
                            this.msg = response.msg;
                        }
                     this.zeige_stationsverbraeuche('neueingabe');

                }
                catch (error) {
                // this.msg = error.response.data.aufgabenliste;
                }

            }
            else {
                alert('Bitte ein Datum auswählen')
            }




         },

        
    
        erhalte_wert_von_textfeld(index) {

             this.update_feld(index)
        },

    
    async  update_feld(index) {
        try {

            const credentials = {
                wert: index[1].replace(",", "."),
                id: index[2],
                id_bezeichnung: index[3],
                feld: index[4],
                tabelle: index[5]
            };
            
            const response = await AufgabenService.update_text_feld(credentials);
            this.zeige_aufgaben() 

            if (response.msg) { //**********  keine gültige Session */
                this.msg = response.msg;
            }
        }
        catch (error) {
            this.msg = "Fehler" //error.response.data.schiffsliste;
        }
    },




        formatDatum (date) {
          if (!date) return null

          var arr= date.split('/'),
                  year = arr[0],
                  month =  arr[1],
                  day=  arr[2];
          return `${day}.${month}.${year}`
        },


        async hole_user_id() {
                try {
                    const credentials = {
                    username: this.username,
                    password: this.password
                    };
                    const response = await AuthService.hole_user_id(credentials);
                    this.current_user_id = response;
                }
                catch(err){
                    console.log('token is null: ',err);
                }
        },

        // popupgeschlossen(){
        //     this.zeige_checkpunkte();
        // },


            async zeige_stationen_auswahl() {
            
                try {
                    const credentials = {
                        id_user: this.current_user_id
                    };

                    const response = await StationsverbraeucheService.zeigestationen(credentials);
                    if (typeof response.msg !=='undefined'){this.$router.push("/login")} //****  wenn keine Anmeldung */
                    this.stationslistesliste_auswahl = response.recordset;
                }
                catch (error) {
                // this.msg = error.response.data.aufgabenliste;
                }
            },


            async zeige_stationsverbraeuche(auswahl) {
               
                //*** wenn Sar-Einheit in Dropdown ausgewählt wurde, dann diesen Wert in localStorage speichern //******  globale session variabel setzen  (siehe in App.vue  / mounted und watch ) */
                if(typeof(this.selected_id_station) !== "undefined" && this.selected_id_station !=='' ) { localStorage.id_station_ausgewaehlt_global = this.selected_id_station  } 
                
                this.ladetabelle = true
                try {
                    var credentials = {
                        id_station: localStorage.id_station_ausgewaehlt_global,
                        auswahl: auswahl

                    };

                    // Hier werden die Daten abgerufen (aufruf axios in script)
                    const response = await StationsverbraeucheService.zeigestationsverbraeuche(credentials);

                    if (typeof response.msg !=='undefined'){this.$router.push("/login")} //****  wenn keine Anmeldung */ 

                    this.ladetabelle = false  //*****  Lade Daten Symbol (Stfreifen)) */
                    this.stationsverbrauchsliste = response.recordset;
                    
                    //************* setze den ausgewählten Schiffsnamen *******/
                     this.selected_id_station = this.stationsverbrauchsliste[0].id_station;

                }
                catch (error) {
                // this.msg = error.response.data.aufgabenliste;
                }
            },


            zum_login() {
                this.$router.push("/login");
            }

    },


//     computed: {
//         selected_id_sar_aus_store () {
//             return localStorage.id_sar_ausgewaehlt_global
//         }
// 
//    
//     },

    created() {

        
            if (!this.$store.getters.isLoggedIn) {
                this.$router.push('/login');
            }
                this.hole_user_id();
                this.zeige_stationen_auswahl();
               // this.zeige_checkpunkte();
    },

    components: { 
     
    //   Popup_infos
    }
};

</script>


<style scoped>

@media screen and (max-width : 500px)
    {
    .schriftgroesse_normal {
    font-size:11px;
    }
    .schriftgroesse_kurztext {
    font-size:14px;
    }
}


@media screen and (min-width : 501px)
    {
    .schriftgroesse_normal {
    font-size:12px;
    }
    .schriftgroesse_kurztext {
    font-size:15px;
    }
}

@media screen and (min-width : 701px)
    {
    .schriftgroesse_normal {
    font-size:14px;
    }
    .schriftgroesse_kurztext {
    font-size:15px;
    }
}

</style>
