<template>
  <v-app id="inspire">
    
        <v-navigation-drawer v-model="drawer" app >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6 grey--text">
                    Menü
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

          <v-divider></v-divider>

              <v-list dense nav>
                <v-list-item  v-for="item in items" :key="item.title" @click="$router.push({ name: item.name, path: item.to, params: {id:para }})" link router-link  class="SelectedTile"  active-class="linkActiveClass">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}{{ item.id }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
        </v-navigation-drawer>
            <v-divider></v-divider>

        <v-app-bar height="80" app color="grey" prominent dark >
 <!-- src="../public/hintergrund.jpg" -->
 
            <template v-slot:img="{ props }">
              <!-- <v-img v-bind="props" gradient="to top right, rgba(84, 110, 122,.9), rgba(176, 190, 197, 1)" ></v-img> -->
              <!-- https://www.gradient-animator.com/ -->
              <v-img v-bind="props" class="hintergrund_gradient_animation" ></v-img>
            </template>

            <v-app-bar-nav-icon  @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-app-bar-title  class="pl-2 pb-6  ">Tech-Manager</v-app-bar-title>

            <v-spacer></v-spacer>

            <!-- <v-app-bar-nav-icon class="mr-12" @click="parameter_aendern('login', 'login')">Param ändern</v-app-bar-nav-icon> -->

            <v-btn icon small class="ml-7 mr-8 mt-2" @click="logout">
              <v-icon >mdi-logout</v-icon>
              Logout
            </v-btn>

            <img class="m-5 ml-auto mt-2" position='right' :src="require('../public/logo.png')" height="60"/>
            
        </v-app-bar>

    <v-main>
      
      <keep-alive>
          <!-- <router-view v-on:check="drawer=!drawer,para='neuer Param' "  v-on:check1="drawer=!drawer,para='uuuuuuuuuuuu' ">www</router-view> -->
          <router-view ></router-view>
      </keep-alive>

    </v-main>


  </v-app>
  
</template>

<script>

// import { EventBus } from './main';


  export default {
    data: () => ({ 
    drawer: null ,
    para: ' Zeile 98 - paarmeeter von data',
   // eventbusparameter:'',
    
//***********************************  ICONS-Liste:   https://materialdesignicons.com/    ***********************/

      items: [
            { title: 'Login', name: 'login', icon: 'mdi-login' ,to:'/login'},
            // { title: 'Registrieren', name: 'sign-up', icon: 'mdi-account-plus'  , to:'/sign-up'},
            { title: 'Home', name: 'home', icon: 'mdi-home' , to:'/home'  },
            { title: 'Schiffsliste', name: 'schiffsliste', icon: 'mdi-ferry' , to:'/schiffsliste'  },
             { title: 'Gruppenmodulliste', name: 'gruppenmodulliste', icon: 'mdi-animation' , to:'/gruppenmodulliste' },
            { title: 'Einzelmodulliste', name: 'einzelmodulliste', icon: 'mdi-animation' ,  to:'/einzelmodulliste' },

            // { title: 'Aufgabenliste', name: 'aufgabenliste', icon: 'mdi-book-open' , to:'/Aufgabenliste' },
            { title: 'Aufgabenliste', name: 'aufgabenliste_neu', icon: 'mdi-developer-board' , to:'/Aufgabenliste_neu' },
            { title: 'Checkpunkte', name: 'checkpunkte', icon: 'mdi-checkbox-marked-outline' , to:'/Checkpunkte' },
           // { title: 'Stationsverbräuche', name: 'stationsverbraeuche', icon: 'mdi-home-automation' , to:'/stationsverbraeuche' },
          ],
      right: null,
      id_sar_ausgewaehlt_global:'',
      name_sar_ausgewaehlt_global:''
      
    }),

    // created() {
    //     EventBus.$on('testeventbus', eventbusparameter => {
    //       console.log(eventbusparameter)
    //     })
    //       
    // },

    mounted() {
      
    if (localStorage.id_sar_ausgewaehlt_global) {
      this.id_sar_ausgewaehlt_global = localStorage.id_sar_ausgewaehlt_global;
    }

    if (localStorage.name_sar_ausgewaehlt_global) {
      this.name_sar_ausgewaehlt_global = localStorage.name_sar_ausgewaehlt_global;
    }


    
  },

  // watch: {
  //     id_sar_ausgewaehlt_global(newID_sar) {
  //     localStorage.id_sar_ausgewaehlt_global = newID_sar;
  //   }
  // },

    methods: {

      parameter_aendern(adresse, ziel) {
        this.para = this.para + " neu ";
        this.$router.push({ name: adresse, path: ziel, params: {id: this.para }})
        this.$emit('check', '')
        
      },
      logout() {
      this.$store.dispatch('logout');
     // this.$router.push('/login/:idlo');
      this.$router.push('/login');
    }
    },
   // emits: ['check'] ,
   

   
  }
</script>
<style>

h1 {color:grey; }
main div {color:grey}





.linkActiveClass {
  color:blue;

}


.v-list-item__title .active{
  color:blue;

}

#nav  {
  color:blue;

}

.nav li:hover {
  color:green

}


.SelectedTile {
  color:green
}

.active-link {color:red; }

.exact-active-link {
  color:red;
}






.hintergrund_gradient_animation {
  /* background-image: url('../public/hintergrund_banner.jpg') no-repeat; */
    background: linear-gradient(90deg, rgba(82,101,112,1) 28%, rgba(171,181,187,1) 100%);
    background-size: 800% 800%;

    -webkit-animation: AnimationName 30s ease infinite;
    -moz-animation: AnimationName 30s ease infinite;
    -o-animation: AnimationName 30s ease infinite;
    animation: AnimationName 30s ease infinite;/*  */
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 59%}
    50%{background-position:100% 42%}
    100%{background-position:0% 59%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 59%}
    50%{background-position:100% 42%}
    100%{background-position:0% 59%}
}
@-o-keyframes AnimationName {
    0%{background-position:0% 59%}
    50%{background-position:100% 42%}
    100%{background-position:0% 59%}
}
@keyframes AnimationName {
    0%{background-position:0% 59%}
    50%{background-position:100% 42%}
    100%{background-position:0% 59%}
}




</style>