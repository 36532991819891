// src/services/AuthService.js

import axios from 'axios';
//import Schiffeservive_indexdb from './SchiffeService_indexdb.js';

import serveradresse_settings from '@/settings/serveradresse_settings.js';
const url = serveradresse_settings.config_server_adresse.url;

//const Schiffeservive_indexdb1 = Schiffeservive_indexdb;




export default {
  async zeigeschiffe(credentials) {
    return axios
      .post(url + 'zeigeschiffe/', credentials)
      .then(response => response.data);


  },

  async zeige_alle_schiffe() {
    return axios
      .post(url + 'zeige_alle_schiffe/')
      .then(response => response.data);
  },
  
 async zeigeschiff_per_id(id_sar) {
    return axios
      .post(url + 'zeigeschiff_per_id/', id_sar)
    //   .then(Schiffeservive_indexdb1.demo1())
    //  // .then(Schiffeservive_indexdb1.demo2())
    //   .then(Schiffeservive_indexdb1.demo4())
    //   .then(Schiffeservive_indexdb1.demo5())
    //   .then(Schiffeservive_indexdb1.demo6())
      .then(response => response.data);
  },
  
  async update_schiff(credentials) {
    return axios
      .post(url + 'update_schiff/', credentials)
      .then(response => response.data);
  },



};